'use strict';

angular.module('risevision.apps', [
    'ui.router',
    'ui.router.upgrade',
    'ui.bootstrap',
    'ngMessages',
    'risevision.common.config',
    'risevision.common.header',
    'risevision.common.components',
    'risevision.common.components.loading',
    'risevision.common.components.scrolling-list',
    'risevision.common.components.logging',
    'risevision.common.components.background-image-setting',
    'risevision.apps.partials',
    'risevision.apps.services',
    'risevision.apps.controllers',
    'risevision.apps.directives',
    'risevision.schedules.services',
    'risevision.displays.services',
    'risevision.editor.services',
    'risevision.storage.services',
    'risevision.storage.controllers',
    'risevision.storage.directives',
    'risevision.storage.filters',
    'risevision.template-editor.services'
  ])
  // Set up our mappings between URLs, templates, and controllers
  .config(['$stateProvider',
    function storeRouteConfig($stateProvider) {

      // Use $stateProvider to configure states.
      $stateProvider
        .state('apps', {
          url: '?cid',
          params: {
            cid: {
              dynamic: true
            }
          },
          abstract: true,
          template: '<div ui-view></div>'
        })

        .state('apps.home', {
          url: '/',
          redirectTo: 'apps.editor.home'
        })

        .state('common.auth.signup', {
          url: '/signup',
          controller: ['$location', '$state', 'canAccessApps',
            function ($location, $state, canAccessApps) {
              // jshint camelcase:false
              var showProduct = $location.search().show_product;
              // jshint camelcase:true

              return canAccessApps(true).then(function () {
                $location.replace();
                if (showProduct) {
                  $state.go('apps.purchase.plans');
                } else {
                  $state.go('apps.home');
                }
              });
            }
          ]
        })

        .state('common.auth.signin', {
          url: '/signin',
          controller: ['$state', 'canAccessApps',
            function ($state, canAccessApps) {
              canAccessApps().then(function () {
                $state.go('apps.home', null, {
                  location: 'replace'
                });
              });
            }
          ]
        });
    }
  ])
  .config(['$tooltipProvider',
    function storeRouteConfig($tooltipProvider) {
      $tooltipProvider.setTriggers({
        'show': 'hide'
      });
    }
  ])
  .run(['$window',
    function ($window) {
      if ($window.Stretchy && $window.Stretchy.selectors) {
        $window.Stretchy.selectors.filter = '.input-stretchy, .input-stretchy *';
      }
    }
  ])
  .config(['$urlServiceProvider',
    function($urlService) {
      $urlService.deferIntercept();
    }
  ])
  .run(['$rootScope', '$state', '$location', '$transitions', '$exceptionHandler',
    function ($rootScope, $state, $location, $transitions, $exceptionHandler) {

      $rootScope.$on('risevision.user.signedOut', function () {
        $state.go('common.auth.unauthorized');
      });

      $transitions.onError({}, function(transition) {
        var error = transition.error();

        // angular-ui-router/stateEvents.js:37 - Ignored (and Invalid) transitions are not handled
        // angular-ui-router/stateEvents.js:200 - Superseded and Aborted transitions are not thrown
        if (error && (error.type === 2 /* RejectType.SUPERSEDED */ || error.type === 3 /* RejectType.ABORTED */ || error.type === 5 /* RejectType.IGNORED */)) {
          // 'superseded' type errors caused by 'redirectTo:'
          return;
        } else if (error && error.detail && error.detail.status === 404) {
          if (!transition.from().name) {
            transition.router.stateService.go('apps.home', transition.params(), {
              location: 'replace'
            });
          }
        }

        $exceptionHandler(error, 'UI Router Error.', true);
      });

      $rootScope.$on('risevision.company.selectedCompanyChanged', function () {
        if ($state.current.name === 'apps.schedules.list' ||
          $state.current.name === 'apps.editor.list' ||
          $state.current.name === 'apps.displays.list' ||
          $state.current.name === 'apps.displays.alerts' ||
          $state.current.name === 'apps.storage.home' ||
          $state.current.name === 'apps.company.details' ||
          $state.current.name === 'apps.company.licenses' ||
          $state.current.name === 'apps.user.list' ||
          $state.current.name === 'apps.screen-sharing.moderator-join' ||
          $state.current.name === 'apps.home') {

          $state.go($state.current, $state.params, {
            reload: true
          });
        } else if (($state.current.name.indexOf('apps.purchase') !== -1 ||
            $state.current.name.indexOf('apps.billing') !== -1) &&
            ($state.current.forceAuth !== false &&
            (!$state.current.params || $state.current.params.forceAuth !== false))) {
          $state.go('apps.billing.home', $state.params, {
            reload: true
          });
        } else if (($state.current.name === 'apps.editor.templates.edit')) {
          if ($state.params.cid !== $location.search().cid) {
            $state.go('apps.editor.list', $state.params);
          }
        }
      });
    }
  ])
  .run(['$rootScope', '$modalStack', 'userState',
    function ($rootScope, $modalStack, userState) {
      $rootScope.$on('$stateChangeStart', function (event) {
        if (userState.isRiseVisionUser()) {
          $modalStack.dismissAll();
        }
      });
    }
  ]);
